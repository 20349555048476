<template>
  <div class="list-user" id="list-user-note">
    <div class="list-user-table">
      <div class="mb-2 row">
        <span
          class="col-12 col-md-8 text-truncate-mess font-title font-weight-bold my-auto cpx-15"
        >
          ノート一覧：{{ userDetail.first_name ? userDetail.first_name : "" }}
        </span>
        <div class="col-12 col-md-4 tex-left text-md-right text-sm-left">
          <b-button
            class="button-back-dashboard ml-0 back-btn"
            v-on:click="returnUserList()"
            >戻る</b-button
          >
        </div>
      </div>
      <div class="row pt-3 cpx-15">
        <label class="col-sm-12 font-weight-bold">ノート作成日時</label>
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <b-form-datepicker
              v-model="created_from"
              v-b-tooltip.hover.top
              class="w-100"
              title="日付を選択"
              placeholder="日付を選択"
              dropup
              v-bind:date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="ja"
              max="9999-12-31"
              labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
            ></b-form-datepicker>
          </div>
        </div>
        <span
          class="col-auto custom-span text-center justify-content-center"
          style="line-height: 25px"
          >~</span
        >
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <b-form-datepicker
              v-model="created_to"
              v-b-tooltip.hover.top
              class="w-100 mr-2"
              title="日付を選択"
              placeholder="日付を選択"
              dropup
              v-bind:date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="ja"
              max="9999-12-31"
              labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
            ></b-form-datepicker>
          </div>
        </div>
      </div>
      <div class="row pt-3 cpx-15">
        <label class="col-sm-12 font-weight-bold pl-3">ノート作成日時</label>
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <b-form-datepicker
              v-model="updated_from"
              v-b-tooltip.hover.top
              class="w-100"
              title="日付を選択"
              placeholder="日付を選択"
              dropup
              v-bind:date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="ja"
              max="9999-12-31"
              labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
            ></b-form-datepicker>
          </div>
        </div>
        <span
          class="col-auto custom-span text-center justify-content-center"
          style="line-height: 25px"
          >~</span
        >
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <b-form-datepicker
              v-model="updated_to"
              v-b-tooltip.hover.top
              class="w-100 mr-2"
              title="日付を選択"
              placeholder="日付を選択"
              dropup
              v-bind:date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="ja"
              max="9999-12-31"
              labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
            ></b-form-datepicker>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <b-button v-on:click.prevent="getListNote()" variant="info" class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          v-on:click.prevent="resetSearch()"
          variant="secondary"
          class="mr-2"
          >リセット</b-button
        >
      </div>
      <Tables
        :items="listNoteShow.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="list-user-note mt-5 cpx-15"
      >
        <template v-slot:ID="{ index }">
          <td>{{ index + listUserNote.from }}</td>
        </template>
        <template v-slot:title="{ item }">
          <td style="min-width: 145px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title }}
            </div>
          </td>
        </template>
        <template v-slot:create_start="{ item }">
          <td>
            {{
              new Date(item.create_start).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:update_end="{ item }">
          <td>
            {{
              new Date(item.update_end).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="pl-2 pr-1 d-flex justify-content-center">
            <div>
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'ListNoteByContent'
                    : 'ListNoteByContent domain',
                  params: {
                    shopId: shop_id,
                    idContent: item.id,
                    idUser: user_id,
                    no: index + listUserNote.from,
                  },
                }"
                class="d-flex flex-column align-items-center text-decoration-none"
              >
                <CButton
                  class="mx-1 btn-other"
                  square
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  ノート確認
                </CButton>
              </router-link>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNoteShow.total"
      >
        <p class="font-weight-bold">
          {{ listNoteShow.total }}件中{{ listNoteShow.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNoteShow.last_page"
      >
        <CPagination
          v-if="listNoteShow.total"
          :activePage.sync="page"
          :pages="listNoteShow.last_page"
          align="center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListUserNote",
  data() {
    return {
      fields: tableFields.USERNOTE,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataModal: "",
      name: "",
      email: "",
      created_from: "",
      created_to: "",
      updated_from: "",
      updated_to: "",
      page: 1,
      // shop_id: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      user_id: null,
      listNoteShow: [],
    };
  },
  components: {
    Tables,
    // DatePick,
  },
  created() {
    const formData = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    this.getUserById(formData);
    const formUser = {
      id: this.$route.params.id,
      data: {
        shop_id: this.shop_id,
        page: this.page,
        limit: Constants.LIMIT,
      },
    };
    this.getListUserNote(formUser);
    const { params } = this.$route;
    // this.shop_id = params.shopId;
    this.user_id = params.id;
  },
  computed: {
    ...mapGetters([
      "listUserNote",
      "message",
      "success",
      "error",
      "userDetail",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listUserNote() {
      this.listNoteShow = this.listUserNote;
      this.page = this.listUserNote.current_page;
    },
    page() {
      this.getListNote(this.page);
    },
  },
  methods: {
    ...mapActions({ getListUserNote: "getListUserNote" }),
    ...mapActions({ getUserById: "getUserById" }),
    getListNote() {
      this.listNoteShow = [];
      const formData = {
        id: this.$route.params.id,
        page: this.page,
        data: {
          shop_id: this.shop_id,
          name: this.name,
          email: this.email,
          created_from: this.created_from,
          created_to: this.created_to,
          updated_from: this.updated_from,
          updated_to: this.updated_to,
          limit: Constants.LIMIT,
        },
      };
      this.getListUserNote(formData);
    },
    returnUserList() {
      this.$router.push({
        name: this.$route.params.shopId ? "list user" : "list user domain",
      });
    },
    resetSearch() {
      this.created_from = "";
      this.created_to = "";
      this.updated_from = "";
      this.updated_to = "";
      this.getListNote();
    },
  },
};
</script>
